import React from "react";
import plugins from '../../content/pluginsInfo'
import Layout from "../components/layout"
// const requireContext = require.context("../a2/countdown", true, /^\.\/.*\.png$/)
// const styleImgs = requireContext.keys().map(requireContext)
export default class CountDown extends React.Component{
    componentWillMount() {
        // window.scrollTo(0,0)
    }


    render() {
        const plugin=plugins[2]
        const styles=plugin.styleImgs.map((style,index)=><li className="animate-up" key={index}>
            <div > <img className="border" src={style} alt="示意图" /></div>
        </li>)
        const demos=plugin.useInDemos.map((demo,index)=>
            <li className="animate-up" key={index}>
                <a href={demo.path}> <img className="border"  src={demo.poster}  alt="示意图" />
                    <div>{demo.name}</div></a>
            </li>
     )
        return(
          <Layout>
            <div>
                <section className="section light-blue cutoff">
                    <div className="pagewidth">
                        <h1>倒计时</h1>
                        <h2>实时显示比赛倒计时</h2>
                    </div>
                </section>

                <section className="section yellow cutoff">
                    <div className="pagewidth">
                        <h1>多种样式</h1>
                        <ul className="grid3 slide-up animated">
                            {styles}
                        </ul>
                    </div>
                </section>


                <section className="section light-gray cutoff">
                    <div className="pagewidth">
                        <h1>适用模板</h1>
                        <ul className="grid3 slide-up animated">
                            {demos}
                        </ul>
                    </div>
                </section>
            </div>
          </Layout>
        )
    }
}